import axios from "@/API/axios";

const category_module = {
  namespaced: true,
  state: {
    categoryResult: [],
    allCategoryResult: [],
    subParentRes: [],
    childCategory: [],
    categoryByStore: [],
  },
  mutations: {
    fetchCategory(state, payload) {
      state.categoryResult = payload.child;
    },
    FETCH_ALL_CATEGORY(state, payload) {
      state.allCategoryResult = payload;
    },
    FETCH_ALL_CHILD_CATEGORY(state, payload) {
      state.childCategory = payload;
      payload.forEach((el) => {
        state.subParentRes = el.children;
        let subParent = el.children;
      });
    },
    FETCH_CATEGORY_BY_STORE(state, payload) {
      state.categoryByStore = payload;
    },
  },
  actions: {
    async getCategoryChild(context, payload) {
      return await axios({
        method: "GET",
        url: `/items/c/${payload.slug}`,
      })
        .then(({ data }) => {
          context.commit("fetchCategory", data.data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    async getCategories(context, payload) {
      return await axios({
        method: "GET",
        url: `/categories`,
      })
        .then(({ data }) => {
          context.commit("FETCH_ALL_CATEGORY", data.data);
          context.commit("FETCH_ALL_CHILD_CATEGORY", data.data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    async getCategoryByStore(context, payload) {
      return await axios({
        method: "GET",
        url: `/categoryByStore/${payload.id}`,
      })
        .then(({ data }) => {
          context.commit("FETCH_CATEGORY_BY_STORE", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_CATEGORY_BY_STORE", []);
          return err;
        });
    },
  },
  modules: {},
};
export default category_module;
