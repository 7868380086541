export default [
  {
    path: "/login",
    name: "Login",
    meta: { ja: "ログイン" },
    component: () => import("../views/Login.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (localStorage.usr_tkn) {
    //     next({ name: "Home" });
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: "/lineCallback",
    name: "Line",
    meta: { ja: "LINEでログイン" },
    component: () => import("../views/Line.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: { ja: "新規登録" },
    component: () => import("../views/Register.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.usr_tkn && localStorage.email) {
        next({ name: "Home" });
      } else if (!localStorage.usr_tkn && !localStorage.email) {
        next({ name: "TempRegister" });
      } else {
        next();
      }
    },
  },
  {
    path: "/checkRegistration",
    name: "CheckRegistration",
    meta: { ja: "登録を確認する" },
    component: () => import("../views/CheckRegistration.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.usr_tkn) {
        next({ name: "Home" });
      } 
      else if (!localStorage.usr_tkn && !localStorage.token && !localStorage.user_register) {
        next({ name: "TempRegister" });
      }
      else {
        next();
      }
    },
  },
  {
    path: "/successRegister",
    name: "SuccessRegister",
    meta: { ja: "成功登録" },
    component: () => import("../views/SuccessRegister.vue"),

    beforeEnter: (to, from, next) => {
      if (localStorage.usr_tkn) {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/tempRegister",
    name: "TempRegister",
    meta: { ja: "仮登録" },
    component: () => import("../views/TempRegister.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.usr_tkn) {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmailRegister",
    meta: { ja: "確認メール" },
    component: () => import("../views/VerifyEmailRegister.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.usr_tkn) {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    meta: { ja: "パスワード忘れた" },
    component: () => import("../views/ForgotPassword.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.usr_tkn) {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    meta: { ja: "リセットパスワード" },
    component: () => import("../views/ResetPassword.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.usr_tkn) {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
];
