module.exports = {
  transpileDependencies: [
    'vuetify'
  ],

  devServer: {
    // public: '0.0.0.0:3000'+process.env.BASE_URL,
    watchOptions: {
      ignored: /node_modules/,
      poll: true
    },
    disableHostCheck: true,
    hot: true,
    inline: false,
    liveReload: true,
    injectClient: false,
    port: 3000,
    sockPath: '/front-ec/sockjs-node'

  },

  publicPath: process.env.BASE_URL,

  pluginOptions: {
    i18n: {
      locale: 'en',
      fallbackLocale: 'en',
      localeDir: 'lang',
      enableInSFC: false
    }
  },

  // configureWebpack: {
  //   output: {
  //     libraryExport: 'default'
  //   },
  //   optimization: {
  //     splitChunks: {
  //       chunks: 'async',
  //       minSize: 30000,
  //       maxSize: 0,
  //       minChunks: 1,
  //       maxAsyncRequests: 100,
  //       maxInitialRequests: 10,
  //       automaticNameDelimiter: '~',
  //       name: true,
  //       cacheGroups: {
  //         vendors: {
  //           test: /[\\/]node_modules[\\/]/, // this is what you are looking for
  //           priority: -10
  //         },
  //         default: {
  //           minChunks: 2,
  //           priority: -20,
  //           reuseExistingChunk: true
  //         }
  //       }
  //     }
  //   }
  // }

}