import axios from "@/API/axios";
import i18n from "../../i18n";
import Swal from "sweetalert2";

const shop_module = {
  namespaced: true,
  state: {
    store: {},
    storeByCondition: {},
    storeFav: [],
    storeDetail: {},
    storeItems: [],
    marketplace: [],
    marketplaceGroup: [],
    itemMarketplace: [],
    storeHeaderItems: [],
    keywordState: "",
    storeAll: [],
    storeHeaderAll: {},
    categoryItemShop: [],
    storeByCategory: [],
    storeCategory: [],
    shopByCategory: [],
    shopChildByCategory: [],
    // itemPickupByStore: [],
    itemListPickupByStore: [],
    stateSearchStore: {},
    pickupByStore: {},
    shopInfo: {},
    shopNews: [],
    categoryName: "",
    pageState: 1,
    lastPage: 1,
    storeFavHeader: {},
    paginationStore: {
      lastPage: 1,
      page: 1,
    },
    is_length: false,
  },
  getters: {
    getShopState(state) {
      return state;
    },
    pagination(state) {
      return state.pageState;
    },
    paginationStore(state) {
      return state.paginationStore;
    },
  },
  mutations: {
    FETCH_STORE(state, payload) {
      state.store = payload;
    },
    FETCH_STORE_BY_CONDITION(state, payload) {
      state.storeByCondition = payload;
    },
    FETCH_STORE_FAV(state, payload) {
      state.storeFav = payload;
    },
    FETCH_STORE_FAV_HEADER(state, payload) {
      state.storeFavHeader = payload;
      if (payload.last_page == undefined) {
        state.is_length = true;
      } else {
        state.is_length = false;
      }
    },
    FETCH_ITEM_STORE(state, payload) {
      state.storeItems = payload.data;
      state.storeHeaderItems = payload;
    },
    FETCH_STORE_ALL(state, payload) {
      state.storeAll = payload.data;
      // state.storeHeaderAll = payload
    },
    FETCH_STORE_HEADER(state, payload) {
      state.storeHeaderAll = payload;
      state.paginationStore.page = payload.page;
    },
    FETCH_SHOP_NEWS(state, payload) {
      state.shopNews = payload;
    },
    FETCH_STORE_DETAIL(state, payload) {
      state.storeDetail = payload;
    },
    FETCH_MARKETPLACE(state, payload) {
      state.marketplace = payload;
    },
    CLEAR_STATE_MARKETPLACE(state, payload) {
      state.marketplace = [];
    },
    FETCH_ITEM_MARKETPLACE(state, payload) {
      state.itemMarketplace = payload;
    },
    CLEAR_STATE_ITEM_MARKETPLACE(state, payload) {
      state.itemMarketplace = [];
    },
    FETCH_MARKETPLACE_GROUP(state, payload) {
      state.marketplaceGroup = payload;
    },
    GET_STORE_PAGINATION(state, payload) {
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true;
        state.paginationStore.page = payload.current_page;
      } else {
        state.is_length = false;
        state.paginationStore.page = payload.current_page;
        state.paginationStore.lastPage = Math.ceil(
          payload.total / payload.per_page
        );
      }
    },
    FETCH_ALL_STORE_BY_CATEGORY(state, payload) {
      state.storeByCategory = payload;
    },
    FETCH_ONE_STORE_BY_CATEGORY(state, payload) {
      state.storeByCategory = payload.data;
    },
    FETCH_CATEGORY_ITEM_SHOP(state, payload) {
      state.categoryItemShop = payload;
    },
    FETCH_SHOP_BY_CATEGORY(state, payload) {
      state.shopByCategory = payload.items.data;
      state.lastPage = payload.items.last_page;
      state.categoryName = payload.category_name;
      state.shopChildByCategory = payload.child;
    },
    PAGINATION(state, payload) {
      if (payload.page !== undefined) {
        state.pageState = payload.page;
      }
    },

    FETCH_ITEM_PICKUP_BY_STORE(state, payload) {
      state.pickupByStore = payload;
      state.itemListPickupByStore = payload.items;
    },

    // FETCH_PICKUP_BY_STORE(state, payload) {
    //   state.itemPickupByStore = payload
    // },

    FETCH_SEARCH_STORE(state, payload) {
      state.stateSearchStore = payload;
    },

    // ABOUT SHOP INFO
    FETCH_SHOP_INFO(state, payload) {
      state.shopInfo = payload;
    },
  },
  actions: {
    async fetchStore(context, payload) {
      await axios({
        method: "GET",
        url: `/items/store/${payload.id}`,
        // url: `/items/store/${payload.id}?page=${payload.page}&filter=${payload.filter}&category=${payload.category}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          localStorage.setItem("getStore", JSON.stringify(data.data));
          context.commit("FETCH_STORE", data.data);
          context.commit("FETCH_STORE_BY_CONDITION", data.data);
          context.commit("FETCH_ITEM_STORE", data.data.items);
        })
        .catch((err) => {
          context.commit("FETCH_STORE", {});
          context.commit("FETCH_ITEM_STORE", []);
          return err;
        });
    },
    async fetchStoreByCondition(context, payload) {
      await axios({
        method: "GET",
        url: `/storeByCondition`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_STORE_BY_CONDITION", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_STORE_BY_CONDITION", {});
          return err;
        });
    },
    async fetchStoreFav({ commit, getters }, payload) {
      if (payload) {
        commit("PAGINATION", payload);
      }
      await axios({
        method: "GET",
        url: `/storefavs/list?page=${getters.pagination}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_STORE_FAV", data.data.data);
          commit("FETCH_STORE_FAV_HEADER", data.data);
        })
        .catch((err) => {
          commit("FETCH_STORE_FAV", []);
          commit("FETCH_STORE_FAV_HEADER", []);
          return err;
        });
    },
    async fetchStoreDetail(context, payload) {
      await axios({
        method: "GET",
        url: `/store/${payload.id}`,
        // url: `/items/store/${payload.id}?page=${payload.page}&filter=${payload.filter}&category=${payload.category}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_STORE_DETAIL", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_STORE_DETAIL", {});

          return err;
        });
    },
    async fetchMarketplace(context, payload) {
      await axios({
        method: "GET",
        //url: `/marketplace/list/${payload.id}`,
        url: `/marketplace/listMarketplace/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          //console.log(data.data[0].hasOwnProperty("item_id"));
          // if (data.data[0].hasOwnProperty("item_id")) {
          //   context.commit("FETCH_MARKETPLACE", [
          //     { data: data.data },
          //     { isItem: true },
          //   ]);
          // } else {
          //   context.commit("FETCH_MARKETPLACE", [
          //     { data: data.data },
          //     { isItem: false },
          //   ]);
          // }
          context.commit("FETCH_MARKETPLACE", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_MARKETPLACE", []);

          return err;
        });
    },
    clearStateMarketplace(context, payload) {
      context.commit("CLEAR_STATE_MARKETPLACE");
    },
    async fetchItemMarketplace(context, payload) {
      await axios({
        method: "GET",
        url: `/marketplace/listItemMarketplace/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ITEM_MARKETPLACE", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ITEM_MARKETPLACE", []);

          return err;
        });
    },
    clearStateItemMarketplace(context, payload) {
      context.commit("CLEAR_STATE_ITEM_MARKETPLACE");
    },
    async fetchMarketplaceGroup(context, payload) {
      await axios({
        method: "GET",
        url: `/marketplace_group/list/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_MARKETPLACE_GROUP", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_MARKETPLACE_GROUP", []);

          return err;
        });
    },
    // async fetchItemPickupByStore(context, payload) {
    //   await axios({
    //     method: "GET",
    //     url: `/items/randomItemByStore/${payload.id}`,
    //   })
    //   .then(({ data }) => {
    //     context.commit("FETCH_PICKUP_BY_STORE", data.data)
    //   })
    //   .catch((err) => {
    //     context.commit("FETCH_PICKUP_BY_STORE", [])
    //     return err.response.message
    //   })
    // },
    async fetchItemListPickupByStore(context, payload) {
      await axios({
        method: "GET",
        url: `/items/pickupstore/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ITEM_PICKUP_BY_STORE", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ITEM_PICKUP_BY_STORE", { items: [] });
          return err.response.message;
        });
    },
    async fetchAllStore({ commit, getters }, payload) {
      if (payload) {
        commit("FETCH_STORE_HEADER", payload);
      }
      return await axios({
        method: "GET",
        url: `/store?page=${getters.paginationStore.page}`,
      })
        .then(({ data }) => {
          commit("FETCH_STORE_ALL", data.data);
          commit("GET_STORE_PAGINATION", data.data);
        })
        .catch((err) => {
          let data = { data: [] };
          commit("FETCH_STORE_ALL", data);
          commit("GET_STORE_PAGINATION", {});
          return err.response.data;
        });
    },
    async fetchCategoryItemShop(context, payload) {
      return await axios({
        method: "GET",
        url: `/store/categoryItemShop/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_CATEGORY_ITEM_SHOP", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_CATEGORY_ITEM_SHOP", []);
        });
    },
    async getStoreListByCategory(context, payload) {
      return await axios({
        method: "GET",
        url: `/storebycategory`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ALL_STORE_BY_CATEGORY", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ALL_STORE_BY_CATEGORY", []);
        });
    },
    async getStoreCategory(context, payload) {
      return await axios({
        method: "GET",
        url: `/store?category_slug=${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ONE_STORE_BY_CATEGORY", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ONE_STORE_BY_CATEGORY", []);
        });
    },
    async shopCategory({ getters, commit }, payload) {
      if (payload) {
        commit("PAGINATION", payload);
      }
      let res = await axios({
        method: "GET",
        url: `/store/c/${payload}?page=${getters.pagination}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_SHOP_BY_CATEGORY", data.data);
        })
        .catch((err) => {
          commit("FETCH_SHOP_BY_CATEGORY", []);
        });
      return res;
    },


    // ABOUT SHOP INFO
    async shopInfo(context, payload) {
      await axios({
        method: "GET",
        url: `/page/storeinfo/${payload.shopId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_SHOP_INFO", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_SHOP_INFO", []);
          return err;
        });
    },

    // SHOP NEWS
    async fetchShopNews(context, payload) {
      await axios({
        method: "GET",
        url: `/news/list`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_SHOP_NEWS", data.data);
          //console.log(data);
        })
        .catch((err) => {
          context.commit("FETCH_SHOP_NEWS", []);

          return err;
        });
    },
    addStoreFavorite(context, payload) {
      let data = axios({
        method: "PUT",
        url: "/storefavs/add/" + payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          if (data.message === "お気に入りに商品が追加されました") {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: data.message,
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: data.message,
            });
          }

          let shop_id = { id: payload };

          this.dispatch("shop_module/fetchStoreFav");
          this.dispatch("order_module/orderHistoryByStore");
          this.dispatch("shop_module/fetchStore", shop_id);

          return data;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            $cookies.set("isLogin", 0);
            localStorage.removeItem("usr_tkn");

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Login first please!",
            });
          }
          return err.response.data;
        });
      return data;
    },
  },
};
export default shop_module;
