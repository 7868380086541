import axios from "../../API/axios";

const itemList_module = {
  namespaced: true,
  state: {
    itemListResult: [],
    itemListPickup: [],
    itemHeaderResult: [],
    itemListPickupByStore: [],
    pageState: 1,
    lastPage: 1,
    currentPage: null,
    filterState: "",
    searchResult: [],
    categoryChild: [],
    dailyCategoryChild: [],
    childDaily: [],
    categoryNameParent: {},
    shopCategoryName: {},
    shopCategoryItems: [],
    categoryWithChild: [],
    itemsOfParentCategory: [],
    parentCategory: {},
    categoryName: "",
    is_length: false,
    isLengthCategory: false,
    pageState: 1,
    categoryByStore: {},
    // search: {
    //   lastPage: 0,
    //   page: 1,
    //   keywordState: "",
    //   storeId: ""
    // }
    search_item: {
      last_page: 0,
      page: 1,
      keyword_state: "",
      store_id: "",
    },
  },
  getters: {
    getItemListState(state) {
      return state;
    },
    search(state) {
      return state.search_item;
    },
    pagination(state) {
      return state.pageState;
    },
  },
  mutations: {
    fetchItemList(state, payload) {
      state.itemListResult = payload.data;
      state.itemHeaderResult = payload;
      state.lastPage = payload.last_page;
      state.currentPage = payload.current_page;
    },
    fetchChild(state, payload) {
      state.categoryChild = payload;
    },
    addFilterState(state, payload) {
      if (payload.keyword !== undefined) {
        state.keywordState = payload.keyword;
      }
      if (payload.page !== undefined) {
        state.pageState = payload.page;
      }
      if (payload.filter !== undefined) {
        state.filterState = payload.filter;
      }
    },

    FETCH_ITEM_PICKUP_BY_STORE(state, payload) {
      state.itemListPickupByStore = payload;
    },

    // ITEMLIST PICKUP
    FETCH_ITEMLIST_PICKUP(state, payload) {
      state.itemListPickup = payload;
    },
    clearItemList(state) {
      state.itemListResult = [];
      state.itemHeaderResult = [];
    },
    fetchSearch(state, payload) {
      state.searchResult = payload;
    },
    CLEAR_STATE_SEARCH(state, payload) {
      state.searchResult = [];
    },
    FETCH_SEARCH_HEADER(state, payload) {
      state.search_item.last_page = payload.last_page;
      if (payload.last_page == undefined) {
        state.is_length = true;
      } else {
        state.is_length = false;
      }
    },
    FILTER_SEARCH(state, payload) {
      if (payload.keyword !== undefined) {
        state.search_item.keyword_state = payload.keyword;
      }
      if (payload.page !== undefined) {
        state.search_item.page = payload.page;
      }
      if (payload.storeId !== undefined || null) {
        state.search_item.store_id = payload.storeId;
      } else {
        state.search_item.store_id = "";
      }
    },
    DAILY_NECESSITY(state, payload) {
      state.categoryWithChild = payload;
      state.itemsOfParentCategory = payload.items.data;
    },
    FETCH_CHILD_DAILY(state, payload) {
      state.dailyCategoryChild = payload;
      // state.last_page = payload.last_page
    },
    FETCH_CATEGORY_NAME(state, payload) {
      state.shopCategoryName = payload;
    },
    FETCH_ITEMS_CATEGORY(state, payload) {
      state.shopCategoryItems = payload;
    },
    fetch_parent_category(state, payload) {
      state.parentCategory = payload;
    },
    CATEGORY_NAME(state, payload) {
      state.categoryName = payload.category_name;
    },
    PAGINATION(state, payload) {
      if (payload.page !== undefined) {
        state.pageState = payload.page;
      }
    },
    FETCH_CATEGORY_BY_STORE_HEADER(state, payload) {
      state.categoryByStore = payload;
      if (payload.last_page == undefined) {
        state.isLengthCategory = true;
      } else {
        state.isLengthCategory = false;
      }
    },
  },
  actions: {
    // list item for result search
    async itemList({ commit, getters }, payload) {
      if (payload) {
        commit("addFilterState", payload);
      }
      const listOfItems = await axios({
        method: "GET",
        url: `/items?page=${getters.getItemListState.pageState}&filter=${getters.getItemListState.filterState}`,
      })
        .then(({ data }) => {
          commit("fetchItemList", data.data);
        })
        .catch((err) => {
          let data = { data: [] };
          commit("fetchItemList", data);
          return err.response.data;
        });
      return listOfItems;
    },
    clearStateSearch(context, payload) {
      context.commit("CLEAR_STATE_SEARCH");
    },
    async itemSearch({ commit, getters }, payload) {
      if (payload) {
        commit("FILTER_SEARCH", payload);
      }
      let data = await axios({
        method: "GET",
        url: `/items?keyword=${getters.search.keyword_state}&storeId=${getters.search.store_id}&page=${getters.search.page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("fetchSearch", data.data.data);
          commit("FETCH_SEARCH_HEADER", data.data);
        })
        .catch((err) => {
          commit("fetchSearch", []);
          commit("FETCH_SEARCH_HEADER", {});
          return err.response.data;
        });
      return data;
    },
    async itemByCategory({ commit, getters }, payload) {
      commit("clearItemList");
      if (payload) {
        commit("addFilterState", payload);
      }
      return await axios({
        method: "GET",
        url: `/items/c/${payload.slug}?page=${getters.getItemListState.pageState}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_CHILD_DAILY", data.data.items.data);
          commit("fetch_parent_category", data.data.parent);
          commit("CATEGORY_NAME", data.data);
          commit("fetchItemList", data.data.items);
          commit("fetchChild", data.data.child);
        })
        .catch((err) => {
          commit("fetchChild", []);
          return err.response.data;
        });
    },
    async dailyNecessity({ commit, getters }, payload) {
      return await axios({
        method: "GET",
        url: `/items/c/${payload.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("DAILY_NECESSITY", data.data);
        })
        .catch((err) => {
          commit("DAILY_NECESSITY", []);
          return err.response.data;
        });
    },
    async categoryDetailByStore({ commit, getters }, payload) {
      if (payload) {
        commit("PAGINATION", payload);
      }
      await axios({
        method: "GET",
        url: `/items/c/${payload.slug}/${payload.id}?page=${getters.pagination}`,
      })
        .then(({ data }) => {
          commit("FETCH_CATEGORY_NAME", data.data);
          commit("FETCH_ITEMS_CATEGORY", data.data.items.data);
          commit("FETCH_CATEGORY_BY_STORE_HEADER", data.data.items);
        })
        .catch((err) => {
          commit("FETCH_CATEGORY_NAME", {});
          commit("FETCH_ITEMS_CATEGORY", []);
          commit("FETCH_CATEGORY_BY_STORE_HEADER", {});
          return err.response.data;
        });
    },

    // ===== HIDE ITEMLIST PICKUP ======
    // async itemListPickup(context, payload) {
    //   await axios({
    //     method: "GET",
    //     url: '/items/randomItem',
    //   })
    //   .then(({ data }) => {
    //     context.commit("FETCH_ITEMLIST_PICKUP", data.data)
    //   })
    //   .catch((err) => {
    //     return err.response.data.message
    //   })
    // },
    clearStatePickup({ commit }) {
      commit("clearstate");
    },
    async itemListPickupByStore(context, payload) {
      // context.commit("clearstate")
      await axios({
        method: "GET",
        url: `/items/pickupstore`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ITEM_PICKUP_BY_STORE", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ITEM_PICKUP_BY_STORE", []);
          return err.response.message;
        });
    },
  },
  modules: {},
};

export default itemList_module;
