import Axios from "../../API/axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "../../router";

const address_module = {
  namespaced: true,
  state: {
    addressList: [],
    reqErrMsg: {},
    addressDetail: [],
    lockerResult: [],
  },
  getters: {},
  mutations: {
    FETCH_ADDRESS_LIST(state, payload) {
      state.addressList = payload;
    },
    GET_ERR_MSG(state, payload) {
      state.reqErrMsg = payload;
    },
    FETCH_ADDRESS_DETAIL(state, payload) {
      state.addressDetail = payload;
    },
    FETCH_LOCKER(state, payload) {
      state.lockerResult = payload;
    },
  },
  actions: {
    addFormAddress(context, payload) {
      let formAdd = Axios({
        url: "/profile/address/add",
        method: "PUT",
        data: {
          address_name: payload.name,
          postal_code: payload.postal,
          prefecture: payload.prefecture,
          building: payload.building,
          district: payload.district,
          city: payload.city,
          ward: payload.ward,
          street_address: payload.street_address,
        },
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          context.commit(`GET_ERR_MSG`, { errMsg: err.response.data.message });
          return err.response.data;
        });
      return formAdd;
    },
    async fetchAddress(context, commit) {
      return await Axios({
        url: "/profile/address",
        method: "GET",
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ADDRESS_LIST", data.data);
        })
        .catch((err) => {
          if(err.response.status == 401) {
            localStorage.removeItem("usr_tkn");
            localStorage.removeItem("isLogin")
            localStorage.removeItem("name_user")
            $cookies.set('isLogin', 0)
            router.push("/login")
          }
          return err.response.message;
        });
    },
    async deleteAddress(context, payload) {
      await Axios({
        url: `/profile/address/${payload.id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          this.dispatch("address_module/fetchAddress");
          return data;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          return err.response.data;
        });
    },
    async fetchAddressById(context, payload) {
      await Axios({
        url: "/profile/address/" + payload,
        method: "GET",
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ADDRESS_DETAIL", data.data);
        })
        .catch((err) => {
          return err;
        });
    },
    updateFormAddress(context, payload) {
      let formAdd = Axios({
        url: "/profile/address/" + payload.id,
        method: "PUT",
        data: {
          address_name: payload.name,
          postal_code: payload.postal,
          prefecture: payload.prefecture,
          ward: payload.ward,
          street_address: payload.street_address,
          building: payload.building,
          district: payload.district,
          city: payload.city,
        },
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          return err.response.data;
        });
      return formAdd;
    },
    async selectedAddress(contex, payload) {
      await Axios({
        url: "/profile/address/" + payload,
        method: "PATCH",
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
    },
    async getAddressByPostal(contex, payload) {
      let url =
        "https://apis.postcode-jp.com/api/v4/postcodes?filter=postcode==" +
        payload.postal;
      return await Axios({
        url: url,
        method: "GET",
        headers: {
          apikey: "VNRQwj8LfTRV41MR41GzeVhS8bTBoFAeWXunFH6",
        },
      })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          return err.response;
        });
    },
    async fetchLocker(context) {
      await Axios({
        method: "GET",
        url: "/locker/getFreeLocker",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit(`FETCH_LOCKER`, data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            router.push("/login");
          }
          return err.response.data;
        });
    },
  },
};

export default address_module;
