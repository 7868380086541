import Axios from "../../API/axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "../../router";
import axios from "axios";

const about_module = {
  namespaced: true,
  state: {
    userProfile: {},
    messageTitle: {},
    openSnackbar: false,
    platformInfo: {}
  },
  mutations: {
    FETCH_MESSAGE_TITLE(state, payload) {
      let data = payload.data.map((el) => {
        return {
          value: el.id,
          label: el.name,
        };
      });
      state.messageTitle = data;
    },
    OPEN_SNACKBAR(state, payload) {
      state.openSnackbar = payload;
    },
    FETCH_TAJIMI_INFO(state, payload) {
      state.platformInfo = payload
    }
  },
  actions: {
    async messageTitle(context, payload) {
      await Axios({
        method: "GET",
        url: "/messagetitles",
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("OPEN_SNACKBAR", false);
          context.commit("FETCH_MESSAGE_TITLE", data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    submitContact(context, payload) {
      return Axios({
        method: "POST",
        url: "/contact",
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
        data: {
          message_title_id: payload.messageTitle,
          order_no: payload.orderNumber,
          first_name: payload.firstName,
          last_name: payload.lastName,
          phone: payload.phone,
          phone_2: payload.phone,
          furigana_1: payload.furigana_1,
          furigana_2: payload.furigana_2,
          email: payload.email,
          contents: payload.content,
          is_call: payload.isCall,
          email_confirmation: payload.email_confirm,
        },
      })
        .then(({ data }) => {
          // router.push('/')
          context.commit("OPEN_SNACKBAR", true);
          setTimeout(() => {
            router.push("/");
          }, 1500);
          return data;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
        });
    },
    fetchTajimiInfo(context, payload) {
      Axios({
        method: "GET",
        url: '/page/platforminfo',
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
      .then(({ data }) => {
        context.commit("FETCH_TAJIMI_INFO", data.data)
      })
      .catch((err) => {
        return err.response.message
      })
    }
  },
};
export default about_module;
