import axios from "../../API/axios";
import router from "../../router";

const order_module = {
  namespaced: true,
  state: {
    orderHistory: [],
    orderHistoryNew: [],
    orderHistoryStore: [],
    orderHistoryItem: [],
    orderHistoryHeader: {},
    orderHistoryNewHeader: {},
    orderHistoryStoreHeader: {},
    orderDetail: {},
    itemOrderByStore: [],
    orderHistoryHeader: {},
    err_msgOrder: "",
    delivDestination: {},
    deliveryTime: [],
    deliveryDate: [],
    orderPaid: {},
    getDeliveryFee: null,
    is_length: false,
    page: 1,
  },
  getters: {
    pagination(state) {
      return state.page;
    },
  },
  mutations: {
    FETCH_ORDER_HISTORY(state, payload) {
      state.orderHistory = payload;
    },
    FETCH_ORDER_HISTORY_STORE(state, payload) {
      state.orderHistoryStore = payload;
    },
    FETCH_ORDER_HISTORY_ITEM(state, payload) {
      state.orderHistoryItem = payload;
    },
    FETCH_ORDER_DETAIL(state, payload) {
      state.orderDetail = payload;
      state.delivDestination = payload.delivery_destination;
    },
    FETCH_ERR_MSG(state, payload) {
      state.err_msgOrder = payload;
    },
    ITEM_ORDER_BY_STORE(state, payload) {
      state.itemOrderByStore = payload;
    },
    DELIVERY_TIME_DATE(state, payload) {
      state.deliveryTime = payload.times;
      state.deliveryDate = payload.dates;
    },
    FETCH_ORDER_PAID(state, payload) {
      state.orderPaid = payload;
    },
    FETCH_ORDER_HISTORY_HEADER(state, payload) {
      state.orderHistoryHeader = payload;
      if (payload.last_page == undefined) {
        state.is_length = true;
      } else {
        state.is_length = false;
      }
    },
    FETCH_ORDER_HISTORY_STORE_HEADER(state, payload) {
      state.orderHistoryStoreHeader = payload;
      if (payload.last_page == undefined) {
        state.is_length = true;
      } else {
        state.is_length = false;
      }
    },
    PAGINATION(state, payload) {
      if (payload.page !== undefined) {
        state.page = payload.page;
      }
    },
    FETCH_HEADER_ORDER_HISTORY_NEW(state, payload) {
      state.orderHistoryNewHeader = payload;
      if (payload.last_page == undefined) {
        state.is_length = true;
      }
      state.is_length = false;
    },
    FETCH_ORDER_HISTORY_NEW(state, payload) {
      state.orderHistoryNew = payload;
    },
    FETCH_DELIVERY_FEE(state, payload) {
      state.getDeliveryFee = payload
    }
  },
  actions: {
    async fetchOrderPaid(context, payload) {
      await axios({
        method: "PUT",
        url: "/orders/paid",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ORDER_PAID", data.data);
          localStorage.removeItem("invoice");
          localStorage.removeItem("cartItems");
        })
        .catch((err) => {
          return err;
        });
    },
    async fetchOrderHistoryNew({ commit, getters }, payload) {
      if (payload) {
        commit("PAGINATION", payload);
      }
      return await axios({
        method: "GET",
        url: `/ordersNew?page=${getters.pagination}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
      .then(({ data }) => {
        commit("FETCH_HEADER_ORDER_HISTORY_NEW", data.data)
        commit("FETCH_ORDER_HISTORY_NEW", data.data.data)
      })
      .catch((err) => {
        commit("FETCH_HEADER_ORDER_HISTORY_NEW", {})
        commit("FETCH_ORDER_HISTORY_NEW", [])
        console.log(err.response);
        return err
      })
    },
    async fetchOrderHistory(context, payload) {
      return await axios({
        method: "GET",
        url: `/orders?page=${payload.page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ORDER_HISTORY_HEADER", data.data);
          context.commit("FETCH_ORDER_HISTORY", data.data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ERR_MSG", err.response.data.message);
          context.commit("FETCH_ORDER_HISTORY_HEADER", {});
          if (err.response.status == 401) {
            localStorage.removeItem("usr_tkn");
            localStorage.removeItem("isLogin");
            localStorage.removeItem("name_user");
            $cookies.set("isLogin", 0);
            router.push("/login");
          }
          return err.response.data;
        });
    },
    async orderHistoryByStore({ commit, getters }, payload) {
      if (payload) {
        commit("PAGINATION", payload);
      }
      return await axios({
        method: "GET",
        url: `/orderstore?page=${getters.pagination}`,
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_ORDER_HISTORY_STORE", data.data.data);
          commit("FETCH_ORDER_HISTORY_STORE_HEADER", data.data);
        })
        .catch((err) => {
          commit("FETCH_ERR_MSG", err.response.data.message);
          commit("FETCH_ORDER_HISTORY_STORE_HEADER", {});
          return err.response.data;
        });
    },
    async orderHistoryByItem(context, payload) {
      return await axios({
        method: "GET",
        url: `/orderitems`,
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ORDER_HISTORY_ITEM", data.data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ERR_MSG", err.response.data.message);
          return err.response.data;
        });
    },
    fetchOrderDetail(context, payload) {
      axios({
        method: "GET",
        url: `/orders/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ORDER_DETAIL", data.data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    async itemOrderByStore(context, payload) {
      await axios({
        method: "GET",
        url: `/items/order/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("ITEM_ORDER_BY_STORE", data.data);
        })
        .catch((err) => {
          context.commit("ITEM_ORDER_BY_STORE", []);
          return err.response.message;
        });
    },
    async deliveryAddressDate(context, payload) {
      await axios({
        method: "GET",
        url: `/deliveryorderdate?store_id=${payload.store_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("DELIVERY_TIME_DATE", data.data);
        })
        .catch((err) => {
          context.commit("DELIVERY_TIME_DATE", []);
          return err.response.message;
        });
    },
    async fetchGetDeliveryFee(context, payload) {
      await axios({
        url: `/getDeliveryFeeConfig`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
      .then(({data}) => {
        context.commit("FETCH_DELIVERY_FEE", data)
      })
      .catch((err) => {
        return err
      })
    }
  },
};

export default order_module;
