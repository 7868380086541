export default [
  // {
  //   path: "/order-history",
  //   name: "OrderHistory",
  //   meta: { ja: "注文履歴" },
  //   component: () => import("../views/OrderHistory.vue"),
  //   children: [
      
  //   ],
  //   beforeEnter: (to, from, next) => {
  //     if (!localStorage.usr_tkn) {
  //       next({ name: "Login" });
  //     } else {
  //       next();
  //     }
  //   },
  // },
  // developmentv2
  {
    path: "/order-history",
    name: "OrderHistory",
    meta: { ja: "注文履歴" },
    component: () => import("../views/developmentV2/OrderHistory.vue"),
    children: [
      
    ],
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/order-history/:id",
    name: "OrderDetail",
    meta: { ja: "注文詳細" },
    component: () => import("../views/OrderDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/order-history/:id/:lockerKey",
    name: "LockerKey",
    meta: { ja: "ロッカーキー" },
    component: () => import("../views/LockerKey.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },

  
]