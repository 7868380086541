export default [
  {
    path: "/store",
    name: "Store",
    meta: { ja: "お店" },
    component: () => import("../views/Store.vue"),
  },
  {
    path: "/store/:id",
    name: "ShopDetail",
    meta: { ja: "ショップ詳細" },
    component: () => import("../views/Shop/ShopDetail.vue"),
  },
  {
    path: "/store/:id/recommendation",
    name: "ShopRecommendation",
    meta: { ja: "ショップレコメンド" },
    component: () => import("../views/Shop/ShopRecommendation.vue"),
  },
  {
    path: "/store/:id/wishlist",
    name: "ShopFavorites",
    meta: { ja: "お気に入りのお店" },
    component: () => import("../views/Shop/ShopFavorites.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/store/:id/purchase-history",
    name: "ShopPurchaseHistory",
    meta: { ja: "ショップ購入履歴" },
    component: () => import("../views/Shop/ShopPurchaseHistory.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/store/:store_id/purchase-history/detail/:id",
    name: "ShopPurchaseHistoryDetail",
    meta: { ja: "ショップ購入履歴詳細" },
    component: () => import("../views/Shop/ShopPurchaseHistoryDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/store/:id/category",
    name: "ShopCategory",
    meta: { ja: "ショップカテゴリー" },
    component: () => import("../views/Shop/ShopCategory.vue"),
  },
  {
    path: "/store/:id/medic",
    name: "ShopInfoMedic",
    meta: { ja: "ショップ情報" },
    component: () => import("../views/Shop/ShopInfoMedic.vue"),
  },
  {
    // path: "/shop/category/items/:slug/:id",
    path: "/store/:id/:slug",
    name: "ShopItemsCategory",
    meta: { ja: "ショップアイテムカテゴリ" },
    component: () => import("../views/Shop/ShopItemsCategory.vue"),
  },
  {
    path: "/shopInfo/:id",
    name: "ShopInfo",
    meta: { ja: "ショップ情報" },
    component: () => import("../views/Shop/ShopInfo.vue"),
    // beforeEnter: (to, from, next) => {
    //   if(!localStorage.usr_tkn) {
    //     next({ name: 'Login' })
    //   } else {
    //     next()
    //   }
    // }
  },
  {
    path: "/favorite-store",
    name: "Favorite Store",
    meta: { ja: "お気に入り店舗" },
    component: () => import("../views/ViewAllFavoriteStore.vue"),
  },
  {
    path: "/shop-news",
    name: "Shop NEWS",
    meta: { ja: "店舗からのお知らせ" },
    component: () => import("../views/ViewAllShopNews.vue"),
  },
  {
    path: "/order-history-store",
    name: "Order History Store",
    meta: { ja: "購入店舗" },
    component: () => import("../views/ViewAllOrderByStore.vue"),
  },
  //   {
  // ROUTE LAMA? (UDAH GA KEPAKE ???) -----------
  //     path: '/viewAllStore/:slug',
  //     name: 'ListViewAllStore',
  //     meta: {'ja': "すべてのストアを表示"},
  //     component: () => import('../views/ListViewAllShop.vue')
  //   },
];
