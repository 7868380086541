<template>
  <v-container class="pb-0 pt-0">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      color="black"
      dark
      app
      style="height: 70vh"
    >
      <template>
        <v-row align="center" justify="space-between">
          <v-col class="mt-4">
            <v-icon
              class="px-4"
              style="margin-top: -20px"
              @click.stop="drawer = !drawer"
            >
              {{ closeThick }}
            </v-icon>
            <span style="color: white; font-size: 25px">{{
              $t("message.title-tajimi")
            }}</span>
          </v-col>
        </v-row>
        <div v-if="isLogin == 0" class="">
          <v-toolbar-title
            style="border-bottom: 2px solid white; margin-top: 20px"
          >
            <router-link to="/login" style="text-decoration: none">
              <span class="ml-6" style="color: white; margin-top: 20px">{{
                $t("message.label-login")
              }}</span>
            </router-link>
          </v-toolbar-title>
          <v-toolbar-title
            style="border-bottom: 2px solid white; margin-top: 20px"
          >
            <router-link to="/tempRegister" style="text-decoration: none">
              <span class="ml-6" style="color: white; margin-top: 20px">{{
                $t("message.label-register")
              }}</span>
            </router-link>
          </v-toolbar-title>
        </div>
        <div v-else-if="isLogin == 1" class="">
          <v-toolbar-title
            style="border-bottom: 2px solid white; margin-top: 20px"
          >
            <router-link to="/account" style="text-decoration: none">
              <span class="ml-6" style="color: white; margin-top: 20px">{{
                $t("message.title-account")
              }}</span>
            </router-link>
          </v-toolbar-title>
          <v-toolbar-title
            style="border-bottom: 2px solid white; margin-top: 20px"
          >
            <router-link to="/address" style="text-decoration: none">
              <span class="ml-6" style="color: white; margin-top: 20px">{{
                $t("message.title-address-management")
              }}</span>
            </router-link>
          </v-toolbar-title>
          <!-- <v-toolbar-title
            style="border-bottom: 2px solid white; margin-top: 20px"
          >
            <router-link to="/bulk" style="text-decoration: none">
              <span class="ml-6" style="color: white; margin-top: 20px">{{
                $t("message.title-bulk-main")
              }}</span>
            </router-link>
          </v-toolbar-title> -->
          <v-toolbar-title
            style="border-bottom: 2px solid white; margin-top: 20px"
          >
            <router-link to="/order-history" style="text-decoration: none">
              <span class="ml-6" style="color: white; margin-top: 20px">{{
                $t("message.order-history-product")
              }}</span>
            </router-link>
          </v-toolbar-title>
          <v-toolbar-title
            style="border-bottom: 2px solid white; margin-top: 20px"
          >
            <router-link to="/carts" style="text-decoration: none">
              <span class="ml-6" style="color: white; margin-top: 20px">{{
                $t("message.label-in-carts")
              }}</span>
            </router-link>
          </v-toolbar-title>
          <v-toolbar-title
            style="border-bottom: 2px solid white; margin-top: 20px"
            @click.prevent="handleLogout"
          >
            <p class="ml-3 ps-3" style="color: white">
              {{ $t("message.btn-logout") }}
            </p>
            <!-- </router-link> -->
          </v-toolbar-title>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      fixed
      color="black"
      dark
      :height="!$vuetify.breakpoint.sm ? 50 : 80"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-spacer />
      <v-img
        :aspect-ratio="16 / 9"
        contain
        width="60"
        height="50"
        max-width="250"
        :src="logo"
        @click="checkIsHome"
      ></v-img>
      <v-spacer />

      <template>
        <v-icon @click.prevent="openModal">
          {{ iconSearch }}
        </v-icon>

        <v-row
          v-if="modal == true"
          class="modal-class"
          style="padding: 0px; width: 98%"
        >
          <div class="col-12 input-search-container">
            <v-form @submit.prevent="submitKeyword()">
              <input
                type="text"
                name="search"
                class="input-field-class"
                v-model="keyword"
              />
              <div
                class="d-flex flex-row align-start justify-end mr-6"
                style="margin-top: -35px; cursor: pointer"
              >
                <v-icon color="black" @click.prevent="submitKeyword()">
                  {{ iconSearch }}
                </v-icon>
              </div>
            </v-form>
          </div>
        </v-row>
      </template>
    </v-app-bar>
  </v-container>
</template>

<script>
import { mdiMagnify, mdiArrowLeft, mdiCloseThick } from "@mdi/js";
import { mdiCart } from "@mdi/js";
import { debounce } from "lodash";
export default {
  name: "Sidebar",
  data() {
    return {
      logo: require("../assets/logo_kbase-removebg.png"),
      badge: 3,
      keyword: "",
      cartIcon: mdiCart,
      closeThick: mdiCloseThick,
      iconSearch: mdiMagnify,
      iconArrowLeft: mdiArrowLeft,
      modal: false,
      clipped: false,
      drawer: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Tajimi",
      listCategory: [
        { text: "Account", route: "/account" },
        { text: "Address Management", route: "/addressManagement" },
        { text: "Order History", route: "/orderHistory" },
      ],
    };
  },

  watch: {},
  computed: {
    isLogin() {
      return $cookies.get("isLogin");
      // return localStorage.getItem('usr_tkn')
    },
    badgeContent() {
      this.badge = this.$store.state.carts_module.badgeLength;
      return this.$store.state.carts_module.badgeLength;
    },
  },
  created() {
    this.setDefaultLogin();
  },
  mounted() {
    this.getSearchItem();
  },
  methods: {
    openModal() {
      if (this.modal == false) {
        this.modal = true;
      } else {
        this.modal = false;
      }
    },
    setDefaultLogin() {
      let islogin = $cookies.get("isLogin");
      if (islogin === 0 || islogin === null) {
        $cookies.set("isLogin", 0);
      }
    },
    submitKeyword() {
      this.modal = false;
      this.$router.push(`/items?search=${this.keyword}`);
      this.$store.dispatch("itemList_module/itemSearch", {
        keyword: this.keyword,
      });
    },
    async handleLogout() {
      await this.$store.dispatch("auth_module/logout", {
        message: {
          title: this.$t("message.title-logout-success-message"),
        },
      });
    },

    getSearchItem() {
      let routeName = this.$route.name;
      if (this.modal || routeName === "SearchResult") {
        let keyparams = this.$route.query.search;
        this.$store.dispatch("itemList_module/itemSearch", {
          keyword: keyparams,
        });
      }
    },
    checkIsHome() {
      let home = this.$route.name;
      if (home === "Home") {
        return 0;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style>
.icon {
  color: black;
}
input {
  padding: 10px 30px 10px 30px;
}
.input-field-class {
  border: 5px solid black !important;
  background-color: white;
  color: black;
  height: 180%;
  width: 100%;
  outline: none;
  border-radius: 10px;
}
/* .breadcrumb li a, .v-application a {

} */
/* input:hover,
input:active,
input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
} */
/*.v-text-field__details {
  display: none !important;
}*/
.v-input__slot {
  margin-bottom: 0px !important;
}
.modal-class {
  position: absolute;
  top: 120%;
  /* top: -50px; */
}
</style>
