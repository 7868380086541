import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ja from "../lang/ja.ts";

// Vue.use(Vuetify), {
//     theme: {
//         login: '#0D3672'
//     },
//     lang: {
//         locales: {ja},
//         current: "ja",
//       },
// }

// export default new Vuetify({
// });

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    login: "#0D3672",
  },
  lang: {
    locales: { ja },
    current: "ja",
  },
});
