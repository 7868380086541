import axios from "@/API/axios";

const itemLastSeen_module = {
  namespaced: true,
  state: {
    itemLastSeenResult: [],
    errMesssage: {},
    itemLastSeenByStore: [],
    headerItemLastSeen: {},
    page: 1,
    is_length: false
  },
  getters: {
    pagination(state) {
      return state.page
    }
  },
  mutations: {
    fetchItemLastSeen(state, payload) {
      state.itemLastSeenResult = payload;
    },
    FETCH_ERR_MESSAGE(state, payload) {
      state.errMesssage = payload;
    },
    FETCH_ITEM_LAST_SEEN_BY_STORE(state, payload) {
      state.itemLastSeenByStore = payload;
    },
    PAGINATION(state, payload) {
      if(payload.page !== undefined) {
        state.page = payload.page
      }
    },
    HEADER_ITEMLASTSEEN(state, payload) {
      state.headerItemLastSeen = payload
      if(payload.last_page == undefined) {
        state.is_length = true
      } else {
        state.is_length = false
      }
    }
  },
  actions: {
    async itemLastSeen({ commit, getters }, payload) {
      if(payload) {
        commit("PAGINATION", payload)
      }
      return await axios({
        method: "GET",
        url: `/items/lastview?page=${getters.pagination}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          commit("fetchItemLastSeen", data.data.data);
          commit("HEADER_ITEMLASTSEEN", data.data)
        })
        .catch((err) => {
          commit("fetchItemLastSeen", []);
          commit("FETCH_ERR_MESSAGE", err.response.data);
        });
    },
    async itemLastSeenByStore(context, payload) {
      await axios({
        method: "GET",
        url: `/items/lastview/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ITEM_LAST_SEEN_BY_STORE", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ITEM_LAST_SEEN_BY_STORE", {});

          return err;
        });
    },
  },
  modules: {},
};

export default itemLastSeen_module;
