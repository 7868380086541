import axios from "@/API/axios";

const itemRecomend_module = {
  namespaced: true,
  state: {
    itemRecomendResult: [],
    itemRecomendHeaderResult: {},
    itemRecommendByStore: [],
    itemRecommendByStoreHeader: {},
    errMesssage: {},
    lastPage: null,
    itemRecLoadMore: [],
    itemRecPage: [],
    current_page:1,
    last_page: 1,
    is_length: false
  },
  getters: {
    getState(state) {
      return state;
    },
  },
  mutations: {
    fetchItemRecomend(state, payload) {
      //   state.itemRecomendResult = [];
      // state.itemRecomendResult.push(...payload);
      state.itemRecomendResult = payload
    },
    ITEM_REC_PAGE(state, payload) {
      // state.itemRecPage.push(...payload.data);
      state.itemRecPage = payload.data
      state.current_page = payload.current_page
      state.last_page = payload.last_page
      // if (state.current_page <= state.last_page) {
      //   state.itemRecPage.push(...payload.data);
      // }  
    },
    ITEM_RECOMMEND_BY_STORE_HEADER(state, payload) {
      state.itemRecommendByStoreHeader = payload;
      if(payload.last_page == undefined) {
        state.is_length = true
      } else {
        state.is_length = false
      }
    },
    fetchItemRecomendHeader(state, payload) {
      state.itemRecomendHeaderResult = payload;
    },
    ITEM_RECOMEND_BY_STORE(state, payload) {
      state.itemRecommendByStore = payload;
    },
  },
  actions: {
    async itemRecomend(context, payload) {
      let data = await axios({
        method: "GET",
        url: "/items/recommendation?page=1",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          context.commit("fetchItemRecomend", data.data.data);
          context.commit("fetchItemRecomendHeader", data.data);
          return data.data;
        })
        .catch((err) => {
          context.commit("fetchItemRecomend", []);
          context.commit("fetchItemRecomendHeader", {});
        });
      return data;
    },

    async itemRecomendByStore(context, payload) {
      await axios({
        method: "GET",
        url: `/items/recommendation/${payload.id}?page=${payload.page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          context.commit("ITEM_RECOMEND_BY_STORE", data.data.data);
          context.commit("ITEM_RECOMMEND_BY_STORE_HEADER", data.data);
        })
        .catch((err) => {
          context.commit("ITEM_RECOMEND_BY_STORE", []);
          context.commit("ITEM_RECOMMEND_BY_STORE_HEADER", {})
          return err.response.message
        });
    },
    async itemRecomendPage({ getters, commit }, payload) {
      let filter = getters.filter;
      return await axios({
        method: "GET",
        url: `/items/recommendation?page=${payload.page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          commit("ITEM_REC_PAGE", data.data);
          // commit("fetchItemRecomendHeader", data.data);
        })
        .catch((err) => {
          commit("ITEM_REC_PAGE", []);
          // commit("fetchItemRecomendHeader", {});
        });
    },
  },
  modules: {},
  getters: {
    filter(state) {
      return state.filter;
    },
  },
};

export default itemRecomend_module;
