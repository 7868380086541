export default [
  {
    path: '/about',
    name: 'About',
    meta: {'ja': "特定商取引法に基づく表記"},
    component: () => import('../views/about/AboutTajimingo.vue'),
  },
  {
    path: '/shopping-terms',
    name: 'ShoppingTerms',
    meta: {'ja': "ショッピング用語"},
    component: () => import('../views/about/ShoppingTerms.vue'),
  },
  {
    path: '/shopping-guide',
    name: 'ShoppingGuide',
    meta: {'ja': "ショッピングガイド"},
    component: () => import('../views/about/ShoppingGuide.vue'),
  },
  {
    path: '/contact',
    name: 'ContactUs',
    meta: {'ja': "お問い合わせ"},
    component: () => import('../views/about/ContactUs.vue'),
  },
  {
    path: '/confirmation',
    name: 'ConfirmationPage',
    meta: {'ja': "お問い合わせ"},
    component: () => import('../views/about/ConfirmationPage.vue'),
    
  },
  {
    path: '/tajimi-info',
    name: 'TajimiInfo',
    meta: {'ja': ""},
    component: () => import('../views/about/TajimiInfo.vue'),
  },
  {
    path: "/term-of-service",
    name: "TajimiTermOfService",
    meta: { ja: "タジミンゴ利用規約" },
    // props: true,
    component: () => import("../views/TajimiTermOfService.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    meta: { ja: "プライバシーポリシー" },
    // props: true,
    component: () => import("../views/about/PrivacyPolicy"),
  },
  {
    path: "/privacy-data",
    name: "PrivacyData",
    meta: { ja: "プライバシーデータ"},
    component: () => import("../views/about/PrivacyData.vue")
  }
]