export default [
  
  {
    path: "/address",
    name: "AddressManagement",
    meta: { ja: "住所管理" },
    component: () => import("../views/AddressManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/address/add",
    name: "AddAddress",
    meta: { ja: "住所の追加" },
    component: () => import("../views/AddAddress.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/address/:id",
    name: "EditAddress",
    meta: { ja: "住所の編集" },
    component: () => import("../views/EditAddress.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
]