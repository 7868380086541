import Axios from "../../API/axios";
import router from "../../router";
import Swal from "sweetalert2/dist/sweetalert2.js";

const userAuth = {
  namespaced: true,
  state: {
    tempEmail: "",
    emailErr: "",
  },
  getters: {},
  mutations: {
    TEMP_REGISTER(state, payload) {
      state.tempEmail = payload;
    },
    EMAIL_ERR(state, payload) {
      state.emailErr = payload;
    },
    
  },
  actions: {
    // authenticated

    authSessionLogin() {
      Axios({
        method: "GET",
        url: "/authenticated",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        if(err.response.status == 401) {
          localStorage.removeItem("usr_tkn");
          localStorage.removeItem("isLogin")
          localStorage.removeItem("name_user")
          $cookies.set('isLogin', 0)
          router.push("/login")
        }
        return err.response.message
      })
    },
    login(context, payload) {
      let dataLogin = Axios({
        url: "/login",
        method: "POST",
        data: {
          email: payload.email,
          password: payload.password,
        },
      })
        .then(({ data }) => {
          localStorage.setItem("name_user", data.authorizedUser.name)
          localStorage.setItem("usr_tkn", data.authorizedUser.token);
          
          $cookies.set('isLogin', 1)
          // router.push("/").catch(()=>{});
          window.location.replace(process.env.BASE_URL)
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'success',
            title: payload.message.title,
          })
        })
        .catch((err) => {
          context.commit("EMAIL_ERR", { emailErr: err.response.data.message });
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1000
          });
          return err.response.data;
        });
      return dataLogin;
    },

    tempRegister(context, payload) {
      Axios({
        method: "POST",
        url: "/tempregister",
        data: {
          email: payload.email,
          redirect_url: payload.url_redirect
        }
      })
      .then(({ data }) => {
        // Swal.fire(data.message)
        router.push('/verifyEmail')
      })
      .catch((err) => {
        localStorage.removeItem("email")
        Swal.fire({
          icon: "error",
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 1000
        });
      })
    },

    lineCallback(context, payload) {
      Axios({
        method: "GET",
        url: `/callback?code=${payload.code}&state=${payload.state}`
      })
      .then(({ data }) => {
        
        if(data.type == 'login'){
          localStorage.setItem("usr_tkn", data.authorizedUser.token)
          localStorage.setItem("name_user", data.lineProfile.username)
          $cookies.set('isLogin', 1)
          router.push('/')
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'success',
            title: data.message,
          })
        } else {
          Swal.fire({
            icon: "success",
            text: data.message,
            showConfirmButton: false,
            timer: 1500
          });
          router.push('/account')
          // setTimeout(() => {
          // }, 1000)
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 1500
        });
        router.push('/')
        return err.response.data.message
      })
    },

    lineLogin(context, payload) {
      Axios({
        method: "GET",
        url: '/getLineUrl',
      })
      .then(({ data }) => {
        location.replace(data.line_url)
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err.response.message,
          showConfirmButton: false,
          timer: 1500
        });
        router.push('/login')
        return err.response.message
      })
    },

    line_integration(context, payload) {
      Axios({
        method: "POST",
        url: "/lineIntegration",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
      .then(({ data }) => {
        location.replace(data.line_url)        
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 1000
        });
        router.push('/account')
        // setTimeout(() => {
        // }, 1000)
        return err.response.message
      })
    },

    // FORGOT PASSWORD
    async cekEmail(context, payload) {
      await Axios({
        method: 'POST',
        url: '/forgotpassword',
        data: {
          email: payload.email,
          url_redirect: payload.url_redirect
        }
      })
      .then(({ data }) => {
        Swal.fire(data.message)
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 1000
        });
      })
    },

    resetPassword(context, payload) {
      Axios({
        method: 'POST',
        url: '/resetpassword',
        data: {
          email: payload.email,
          password: payload.password,
          password_confirmation: payload.confirmPassword,
          token: payload.token
        }
      })
      .then(({ data }) => {
        if(data.status==200){
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'success',
            title: data.message,
          })
          router.push('/login')
        }
        
        
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 1000
        });
      })
    },

    register(context, payload) {
      Axios({
        url: "/register",
        method: "POST",
        data: {
          email: payload.email,
          customer_name: payload.customer_name,
          password: payload.password,
          password_confirmation: payload.password_confirmation,
          phone: payload.phone,
          gender: parseInt(payload.gender),
          prefecture: payload.prefecture,
          postal_code: payload.postal_code,
          ward: payload.ward,
          district: payload.district,
          street_address: payload.street_address,
          furigana: payload.furigana,
          building: payload.building,
          city: payload.city,
          member_id: payload.member_id,
          bod: payload.bod,
          token: payload.token
        },
      })
        .then(({ data }) => {
            localStorage.removeItem("email")
            localStorage.removeItem("token")
            localStorage.removeItem("user_register")
            router.push("/successRegister");
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            text: err.response.data.message,
            showConfirmButton: false,
          timer: 1000
          })
          return err.response.data;
        });
    },
    async logout(context, payload) {
      await Axios({
        url: '/logout',
        method: "POST",
        headers: {
          'Authorization': `Bearer  ${localStorage.getItem("usr_tkn")}`,
        }
      })
      .then((res) => {
        localStorage.removeItem("usr_tkn");
        localStorage.removeItem("name_user")
        

        $cookies.set('isLogin', 0)
        window.location.replace(process.env.BASE_URL)

      })
    },
  },
};

export default userAuth;
