import axios from "@/API/axios";
import Swal from "sweetalert2";

const itemFavorite_module = {
  namespaced: true,
  state: {
    itemFavoriteResult: [],
    itemFavByStore: [],
    headerItemFavs: {},
    is_length: false,
    page: 1,
  },
  mutations: {
    fetchItemFavorite(state, payload) {
      state.itemFavoriteResult = payload;
    },
    ITEM_BY_STORE(state, payload) {
      state.itemFavByStore = payload;
    },
    PAGINATION_FAVS(state, payload) {
      if (payload.page !== undefined) {
        state.page = payload.page;
      }
    },
    HEADER_ITEM_FAVS(state, payload) {
      state.headerItemFavs = payload;
      if (payload.last_page == undefined) {
        state.is_length = true;
      } else {
        state.is_length = false;
      }
    },
  },
  getters: {
    FETCH_FAV_GETTER(state) {
      return state.itemFavoriteResult;
    },
    pagination(state) {
      return state.page;
    },
  },
  actions: {
    async itemFavorite({ commit, getters }, payload) {
      if (payload) {
        commit("PAGINATION_FAVS", payload);
      }
      return await axios({
        method: "GET",
        url: `/items/favs?page=${getters.pagination}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          commit("fetchItemFavorite", data.data.data);
          commit("HEADER_ITEM_FAVS", data.data);
        })
        .catch((err) => {
          commit("fetchItemFavorite", []);
          commit("HEADER_ITEM_FAVS", {});
          if (err.response.status == 401) {
            localStorage.removeItem("usr_tkn");
            localStorage.setItem("isLogin", 0);
          }
          return err.response.data;
        });
    },
    async itemFavoriteByStore(context, payload) {
      await axios({
        method: "GET",
        url: `/items/favs/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          context.commit("ITEM_BY_STORE", data.data);
        })
        .catch((err) => {
          context.commit("ITEM_BY_STORE", []);
          return err.response.message;
        });
    },
    addItemFavorite(context, payload) {
      // console.log(payload, "payload");

      let data = axios({
        method: "PUT",
        url: "/items/favs/" + payload.item_id,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          if (data.message === "Item successfully added to Favourite.") {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: data.message,
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: data.message,
            });
          }

          // refresh item after add to favorite
          switch (payload.page) {
            case "top_page":
              this.dispatch("itemFavorite_module/itemFavorite"); // favorite item
              this.dispatch("itemLastSeen_module/itemLastSeen"); // last view item
              this.dispatch("order_module/orderHistoryByItem"); // order history
              break;
            case "order_history":
              this.dispatch("order_module/fetchOrderHistoryNew"); // detail order history
              break;
            case "wishlist":
              this.dispatch("itemFavorite_module/itemFavorite"); // detail favorite
              break;
            case "last_seen":
              this.dispatch("itemLastSeen_module/itemLastSeen"); // detail last seen
              break;
            case "marketplace":
              //have several marketplace
              if (payload.marketplaceId) {
                this.dispatch("shop_module/fetchItemMarketplace", {
                  id: payload.marketplaceId,
                });
              }

              //only one marketplace
              if (payload.groupMarketplaceId) {
                this.dispatch("shop_module/fetchMarketplace", {
                  id: payload.groupMarketplaceId,
                });
              }

              break;
            case "search":
              this.dispatch("itemList_module/itemSearch", payload);
              break;
            case "category":
              let slug = { slug: payload.slug };
              this.dispatch("itemList_module/dailyNecessity", slug);
              break;
            case "child-category":
              let cslug = { slug: payload.slug, page: 1 };
              this.dispatch("itemList_module/itemByCategory", cslug);
              break;
            case "shop_detail":
              let store_id = payload.store_id;
              this.dispatch(
                "itemFavorite_module/itemFavoriteByStore",
                store_id
              ); // favorite item by store

              this.dispatch("itemLastSeen_module/itemLastSeenByStore", {
                id: payload.store_id,
              }); // last view item by store

              this.dispatch("order_module/itemOrderByStore", store_id); // item order by store

              break;
            case "modal":
              this.dispatch("itemDetail_module/itemDetail", payload.slug); // item detail
              if (payload.parent_page == "/") {
                this.dispatch("itemFavorite_module/itemFavorite"); // favorite item
                this.dispatch("itemLastSeen_module/itemLastSeen"); // last view item
                this.dispatch("order_module/orderHistoryByItem"); // order history
              } else if (payload.parent_page == "/wishlist") {
                this.dispatch("itemFavorite_module/itemFavorite"); // detail favorite
              } else if (payload.parent_page == "/last-seen") {
                this.dispatch("itemLastSeen_module/itemLastSeen"); // detail last seen
              } else if (payload.parent_page == "/order-history") {
                this.dispatch("order_module/fetchOrderHistoryNew"); // detail order history
              } else if (payload.parent_page == "/items") {
                this.dispatch("itemList_module/itemSearch", payload);
              } else if (
                payload.parent_page.substring(0, 16) == "/items/category/"
              ) {
                if (
                  payload.parent_page.substring(0, 18) == "/items/category/c/"
                ) {
                  let slug = { slug: payload.ctg_slug, page: 1 };
                  this.dispatch("itemList_module/itemByCategory", slug);
                } else {
                  let slug = { slug: payload.ctg_slug };
                  this.dispatch("itemList_module/dailyNecessity", slug);
                }
              } else if (payload.parent_page.substring(0, 7) == "/store/") {
                //have several marketplace
                if (payload.marketplaceId) {
                  this.dispatch("shop_module/fetchItemMarketplace", {
                    id: payload.marketplaceId,
                  });
                }

                //only one marketplace
                if (payload.groupMarketplaceId) {
                  this.dispatch("shop_module/fetchMarketplace", {
                    id: payload.groupMarketplaceId,
                  });
                }

                let store_id = payload.store_id;
                this.dispatch(
                  "itemFavorite_module/itemFavoriteByStore",
                  store_id
                ); // favorite item by store

                this.dispatch("itemLastSeen_module/itemLastSeenByStore", {
                  id: payload.store_id,
                }); // last view item by store

                this.dispatch("order_module/itemOrderByStore", store_id); //
              }

              break;
          }

          return data;
        })
        .catch((err) => {
          // console.log(err.response);
          if (err.response.status == 401) {
            $cookies.set("isLogin", 0);
            localStorage.removeItem("usr_tkn");

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Login first please!",
            });
          }
          return err.response.data;
        });
      return data;
    },
  },
  modules: {},
};
export default itemFavorite_module;
