import Vue from 'vue'
import Vuex from 'vuex'
import itemList_module from './modules/itemList_module'
import auth_module from './modules/auth_module'
import itemLastSeen_module from './modules/itemLastSeen_module'
import itemFavorite_module from '@/store/modules/itemFavorite_module'
import itemDetail_module from '@/store/modules/itemDetail_module'
import account_module from '@/store/modules/account_module'
import allCategory_module from './modules/allCategory_module'
import carts_module from './modules/carts_module'
import category_module from '@/store/modules/category_module'
import address_module from '@/store/modules/address_module'
import shop_module from '@/store/modules/shop_module'
import order_module from '@/store/modules/order_module'
import itemRecomend_module from '@/store/modules/itemRecomend_module'
import bulk_module from '@/store/modules/bulk_module'
import about_module from '@/store/modules/about_module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth_module,
    itemList_module,
    itemLastSeen_module,
    itemFavorite_module,
    itemDetail_module,
    account_module,
    allCategory_module,
    carts_module,
    category_module,
    address_module,
    shop_module,
    order_module,
    itemRecomend_module,
    bulk_module,
    about_module
  }
})


