import Vue from "vue";
import VueRouter from "vue-router";
import GroupListRoute from "./bulk_route";
import AboutRoute from "./about_route";
import Shop_route from "./Shop_route";
import auth_route from "./auth_route";
import order_route from "./order_route";
import address_route from "./address_route";
import { publicPath } from "../../vue.config";
Vue.use(VueRouter);

const Home = () => import("@/views/Home.vue");

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      ja: "ホーム",
      KeepAlive: true, // Need to be cached
    },
    component: Home,
  },
  {
    path: "/last-seen",
    name: "LastSeenProduct",
    meta: { ja: "最後に見た商品" },
    component: () => import("../views/LastSeenProduct.vue"),
  },
  {
    path: "/recommendation",
    name: "RecomendProduct",
    meta: { ja: "おすすめ商品" },
    component: () => import("../views/RecomendProduct.vue"),
  },
  {
    path: "/similiar-product/:slug",
    name: "ViewAllSimilarProduct",
    meta: { ja: "類似の商品をすべて表示" },
    component: () => import("../views/ViewAllSimilarProduct.vue"),
  },
  {
    path: "/items",
    name: "SearchResult",
    meta: { ja: "検索結果" },
    component: () => import("../views/SearchResult.vue"),
  },

  {
    path: "/items/:slug",
    name: "ItemDetail",
    meta: { ja: "アイテム詳細" },
    component: () => import("../views/ItemDetail.vue"),
  },
  {
    path: "/items/category/:slug",
    name: "DailyNecessity",
    meta: { ja: "カテゴリ" },
    component: () => import("../views/DailyNecessity.vue"),
  },
  {
    path: "/items/category/c/:slug/:page",
    name: "itemCategory",
    meta: { ja: "カテゴリ" },
    component: () => import("../views/itemCategory.vue"),
  },
  {
    path: "/wishlist",
    name: "FavoritesProduct",
    meta: { ja: "お気に入り" },
    component: () => import("../views/FavoritesProduct.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/account",
    name: "Account",
    meta: { ja: "アカウント" },
    // name: "アカウント",
    component: () => import("../views/Account.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/account/update",
    name: "EditAccount",
    meta: { ja: "アカウント編集" },
    // name: "アカウント",
    component: () => import("../views/EditAccount.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/category-top",
    name: "CategoryTop",
    meta: { ja: "すべてのカテゴリ" },
    component: () => import("../views/CategoryTop.vue"),
  },
  {
    path: "/carts",
    name: "Cart",
    meta: { ja: "カート" },
    component: () => import("../views/Cart.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/select-locker",
    name: "SelectLocker",
    meta: { ja: "ロッカーを選択" },
    component: () => import("../views/SelectLocker.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  ...GroupListRoute,
  ...AboutRoute,
  ...Shop_route,
  ...auth_route,
  ...order_route,
  ...address_route,
  {
    path: "/success",
    name: "Success",
    meta: { ja: "成功" },
    component: () => import("../views/Success.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else if (localStorage.usr_tkn && !localStorage.invoice) {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/failed",
    name: "Failed",
    meta: { ja: "失敗" },
    component: () => import("../views/Failed.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.usr_tkn) {
        next({ name: "Login" });
      } else if (localStorage.usr_tkn && !localStorage.invoice) {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },


  {
    path: "*",
    name: "NotFound",
    meta: { ja: "見つかりません" },
    component: () => import("../views/NotFound.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  base: publicPath,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  if (process.env.VUE_APP_I18N_LOCALE == "ja") {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.ja}`;
  }
  next();
});

export default router;
