import axios from "../../API/axios";
import Swal from "sweetalert2";
import router from '../../router';

const carts_module = {
  namespaced: true,
  state: {
    dataCarts: [],
    badgeLength: "",
    totalItemPrice: null
  },
  mutations: {
    fetchCarts(state, payload) {
      state.dataCarts = payload;
      let totalOrder = 0
      state.dataCarts.map(el => {
        totalOrder += el.total_amount_w_delivery_fee_per_store

      })
      state.totalItemPrice = totalOrder
    },
    BADGE_LENGTH(state, payload) {
      let countStore = 0;
      payload.map((val, idx) => {
        val.detail.map((valItem, idxItem) => {
          countStore++;
        });
      });
      state.badgeLength = countStore;
    },
  },
  actions: {
    addCarts(context, payload) {
      axios({
        method: "PUT",
        url: "/carts/add",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
          Accept: "application/json",
        },
        data: {
          store_id: payload.storeId,
          item_id: payload.itemId,
          qty: 1
        },
      })
        .then((response) => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'success',
            title: payload.message.title
          })
          // window.location.replace(`${process.env.BASE_URL}carts`)
          this.dispatch("carts_module/fetchCarts");
        })
        .catch((err) => {
          if(err.response.status == 401) {
            localStorage.removeItem("name_user")
            $cookies.set('isLogin', 0)
            localStorage.removeItem("usr_tkn");
            router.push("/login")
          } else if(err.response.status == 400) {
            Swal.fire({
              icon: "error",
              text: err.response.data.message,
              showConfirmButton: false,
              timer: 1500
            });
          }
          return err.response.data;
        });
    },
    async fetchCarts(context) {
      return await axios({
        method: "GET",
        url: "/cartsnew",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
          Accept: "application/json",
        },
      }).then(({ data }) => {
        context.commit("fetchCarts", data.data);
        context.commit("BADGE_LENGTH", data.data);
      })
      .catch((err) => {
        if(err.response.status == 401) {
          localStorage.removeItem("usr_tkn");
          localStorage.removeItem("isLogin")
          localStorage.removeItem("name_user")
          $cookies.set('isLogin', 0)
          router.push("/login")
        }
        return err.response.message
      })
    },
    editQty(context, payload) {
        return axios({
          method: "PATCH",
          url: `/carts/${payload.cart_id}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
            Accept: "application/json",
          },
          data: {
            qty: payload.newQty,
          },
        })
          .then(({ res }) => {
            this.dispatch("carts_module/fetchCarts");
          })
          .catch((err) => {
            return err.response.data.message
          });
    },
    clearCarts(context, payload) {
      axios({
        method: "PUT",
        // url: `/carts/deletebystore/${payload.store_id}`,
        url: `/carts/clear?store_id=${payload.store_id}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
          Accept: "application/json",
        },
      })
      .then(({ data }) => {
        this.dispatch("carts_module/fetchCarts")
      })
      .catch((err) => {
        return err.response.message
      })
    },
    clearCartsBulk(context, payload) {
      axios({
        method: "PUT",
        // url: `/carts/deletebystore/${payload.store_id}`,
        url: `/carts/clear?group_id=${payload.group_id}&store_id=${payload.store_id}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
          Accept: "application/json",
        },
      })
      .then(({ data }) => {
        this.dispatch("carts_module/fetchCarts")
      })
      .catch((err) => {
        return err.response.message
      })
    },
    deleteCart(context, payload) {
      return axios({
        method: "GET",
        url: `/carts/delete/${payload.cart_id}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
          Accept: "application/json",
        },
      })
        .then(() => {
          this.dispatch("carts_module/fetchCarts");
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    checkoutOrder(context, payload) {
      payload.forEach(val => {
        val.total_order_price = Math.floor(val.total_order_price)
        // val.total_tax = Math.floor/val.total_tax)
        val.total_tax = val.total_tax
      })
      const parsedPayload = JSON.stringify(payload);
      localStorage.setItem("cartItems", parsedPayload);
    },
    async confirmOrder(context, payload) {
      let checkout = await axios({
        url: "/orders/checkout",
        method: "PUT",
        data: payload,
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          this.dispatch("carts_module/fetchCarts");
          return data;
        })
        .catch((err) => {

          Swal.fire({
            icon: 'warning',
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 3000
          })
          return err.response.data;
          // return err.response;
        });
      return checkout;
    },
  },
};

export default carts_module;
