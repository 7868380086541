import Axios from '../../API/axios'
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from '../../router';

const account_module = {
  namespaced: true,
  state: {
    userProfile: {},
    openSnackbar: false,
  },
  mutations: {
    FETCH_USER_PROFILE(state, payload) {
    
      state.userProfile = payload;
    },
    clearstate(state) {
      state.userProfile = {}
    },
    OPEN_SNACKBAR(state, payload) {
      state.openSnackbar = payload
    },
  },
  actions: {
    async fetchUserProfile(context) {
      context.commit("clearstate")
      await Axios({
        method: "GET",
        url: "/profile",
        headers: {
          // "Content-Type": "application/json",
          'Authorization': `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("OPEN_SNACKBAR", false)
          context.commit(`FETCH_USER_PROFILE`, data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            router.push('/login')
            localStorage.removeItem("usr_tkn")
            localStorage.removeItem("name_user")
            $cookies.set('isLogin', 0)
          }
          return err.response.data
        });
    },
    updateAccount(context, payload) {
      return Axios({
        method: "PUT",
        url: '/profile/update',
        headers: {
          // "Content-Type": "application/json",
          'Authorization': `Bearer  ${localStorage.getItem("usr_tkn")}`,
        },
        data: {
          customer_name: payload.name,
          furigana: payload.furigana,
          bod: payload.bod,
          phone: payload.phone,
          gender: payload.gender,
          oldPassword: payload.oldPassword,
          password: payload.password,
          confirmPassword: payload.confirmPassword
        }
      })
      .then(({ data }) => {
        context.commit("OPEN_SNACKBAR", true)
        setTimeout(() => {
          router.push('/account')
        }, 1500)
        return data
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 1000
        })
        // return err.response.data
      })
    }
  },
  modules: {},
};
export default account_module;
