<template>
  <v-app>
    <v-main>
      <!-- <keep-alive include="Home,OrderHistory,FavoritesProduct,Store,CategoryTop"> -->
      <router-view :key="$route.fullPath" />
      <!-- </keep-alive> -->
    </v-main>
  </v-app>
</template>
<script>
import Sidebar from "./components/Sidebar.vue";

export default {
  name: "App",
  components: { Sidebar },
  created() {
    this.isSuccess();
  },
  watch: {
    $route(to){
      // let name = this.$route.name
      // let page = this.$route.query.page
      // if (page > 1) {
      //   // this.$router.replace({ query: { page: this.page - 1 } })
      // }
    }
  },
  
  methods: {
    isSuccess() {
      let payload = localStorage.getItem("invoice");
      if (this.$route.name === "Success") {
        // this.$router.push("/items/search");
        this.$store.dispatch("order_module/fetchOrderPaid", payload);
      }
    },
  },
};
</script>
<style>
.text_color {
  color: #ff0090;
  font-weight: 800;
}
.bg_color {
  color: #ff0090 !important;
}
.v-application p {
  margin: 0px !important;
}
</style>
