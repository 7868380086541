import axios from "../../API/axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from '../../router';


const bulk_module = {
  namespaced: true,
  state: {
    bulkResult: [],
    // searchResult: [],
    bulkSearchResult: [],
    bulkByDateResult: [],
    bulkDetailResult: {},
    bulkItemInListGroup: {},
    bulkStoreInListGroup: {},
    bulkGroupInListGroup: [],
    bulkDetailGroup: {},
    badgeBulkLength: "",
    bulkCarts: [],
    totalItemPriceBulk: null,
    pagLastPage:null,
    bulkDetailHeader: [],
    bulkSelectDate: [],
    merchantName: "",
    date: "",
    // dateFrom: "",
    // dateTo: "",
    stateCatalog: {
      page: 1,
      sortBy:"",
    },

    err_bulkMain: "",
    err_bulkListGroup: ""

  },
  getters: {
    filterDate(state) {
      return state;
    },
    getBulkState(state) {
      return state
    }
  },
  mutations: {
    FETCH_BULK(state, payload) {
      state.bulkResult = payload.data;
      state.pagLastPage = payload.last_page
    },
    CHANGE_STATE_CATALOG(state, payload) {
      if (payload.page !== undefined) {
        state.stateCatalog.page = payload.page
      }
      if (payload.sortBy !== undefined) {
        state.stateCatalog.sortBy = payload.sortBy
      }
    },
    FETCH_BULK_SEARCH(state, payload) {
      state.bulkSearchResult = payload
    },
    FETCH_BULK_BYDATE(state, payload) {
      state.bulkByDateResult = payload;
    },
    FILTERED_BULK_DATE(state, payload) {
      if(payload.date !== undefined) state.date = payload.date
      // if (payload.dateFrom !== undefined) state.dateFrom = payload.dateFrom;
      // if (payload.dateTo !== undefined) state.dateTo = payload.dateTo;
    },

    // ERR MSG BULK MAIN
    FETCH_ERR_MSG(state, payload) {
      state.err_bulkMain = payload
    },

    FETCH_BULK_DETAIL(state, payload) {

      state.bulkDetailHeader = payload
      state.bulkSelectDate = payload.dates
      state.bulkDetailResult = payload.item;
      state.merchantName = payload.merchant_name;
      state.storeName = payload.store_name;
    },
    FETCH_LIST_GROUP(state, payload) {
      state.bulkItemInListGroup = payload.items
      state.bulkGroupInListGroup = payload.groups
      state.bulkStoreInListGroup = payload.store
    },

    // UNTUK ERR MSG LIST GROUP
    FETCH_ERR_LISTGROUP(state, payload) {
      state.err_bulkListGroup = payload
    },

    // UNTUK FETCH DETAIL GROUP
    FETCH_DETAIL_GROUP (state, payload) {
      state.bulkDetailGroup = payload
    },

    // UNTUK FETCH MY CARTS
    FETCH_BULK_CARTS (state, payload) {
      // state.bulkItemCarts = payload.items
      state.bulkCarts = payload
      let totalOrderBulk = 0
      state.bulkCarts.map(el => {
        totalOrderBulk += el.total_order_price
      })
      state.totalItemPriceBulk = totalOrderBulk
    },

    // UNTUK BADGE MYCART BULK
    BADGE_BULK_LENGTH (state, payload) {
      let countStore = 0;
      payload.map((value, idx) => {
        value.items.map((valueItem, idxItem) => {
          countStore++;
        })
      })
      state.badgeBulkLength = countStore
    },

    // FETCH LIST SEARCH
    // fetchSearch(state, payload) {
    //   state.searchResult = payload
    // }
  },
  actions: {
    fetchBulkList({ commit, getters }, payload) {
      
      if (payload) {
        commit("CHANGE_STATE_CATALOG", payload)
      }
      return axios({
        method: "GET",
        url: `/bulk/items?page=${getters.getBulkState.page}&filter=${getters.getBulkState.sortBy}`,
      })
        .then(({ data }) => {
          commit("FETCH_BULK", data.data);
        })
        .catch((err) => {
          return err;
        });
    },
    bulkSearch({commit, getters}, payload) {
      axios({
        method: 'GET',
        url: `/bulk/items?keyword=${payload.keyword}`
      })
      .then(({ data }) => {
        commit('FETCH_BULK_SEARCH', data.data)
      })
      .catch((err) => {
        return err
      })
    },

    // list by date
    bulkByDate({ commit, getters }, payload) {
      if (payload) {
        commit("FILTERED_BULK_DATE", payload);
      }
      return axios({
        method: "GET",
        // url: `/bulk/itemsbydate?datefrom=${getters.filterDate.dateFrom}&dateto=${getters.filterDate.dateTo}`,
        url: `/bulk/itemsbydate?date=${getters.filterDate.date}`,
      })
        .then(({ data }) => {
          commit("FETCH_BULK_BYDATE", data.data);
        })
        .catch((err) => {
          commit("FETCH_BULK_BYDATE", []);
          commit("FETCH_ERR_MSG", err.response.data.message)
          return err.response;
        });
    },

    // bulk detail 
    async bulkDetail({ commit, getters }, payload) {
      return await axios({
        method: "GET",
        url: `/bulk/items/p/${payload}`,
      })
        .then(({ data }) => {
          commit("FETCH_BULK_DETAIL", data.data);
        })
        .catch((err) => {
          return err.response.message;
        });
    },

    // list group
    async bulkListGroup({ commit, getters }, payload) {
      return await axios({
        method: 'GET',
        url: `/bulk/groups/p/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
      .then(({ data }) => {
        commit('FETCH_LIST_GROUP', data.data)
      })
      .catch((err) => {
        commit("FETCH_ERR_LISTGROUP", err.response.data.message)
        commit('FETCH_LIST_GROUP', {items: [], groups: []})
        return err
      })
    },

    // add group 
    addBulkGroupList(context, payload) {
      axios({
        method: 'POST',
        url: '/bulk/groups',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
        data: payload
      })
      .then(({ data }) => {
        // this.dispatch('bulk_module/bulkListGroup')
        
      })
      .catch((err) => {
        Swal.fire({
          icon: 'warning',
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 3000
        })
        return err.response.message
      })
    },

    // group detail
    async fetchBulkDetailGroup(context, payload) {
      return await axios({
        method: 'GET',
        url: `/bulk/group/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
      .then(({ data }) => {
        context.commit('FETCH_DETAIL_GROUP', data.data)
      })
      .catch((err) => {
        return err.response
      })
    },
    async fetchJoinCart(context, payload) {
      await axios({
        method: "PATCH",
        url: '/bulk/groups/joinNew',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
        data: {
          menu_date: payload.menu_date,
          store_id: payload.storeId,
          address_id: payload.address,
          item_id: payload.itemId
        }
      })
      .then(({ res }) => {
        return res
      })
      .catch((err) => {
        Swal.fire({
          icon: 'warning',
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 3000
        })
        return err
      })
    },

    // FETCH BULK MYCART
    async fetchBulkCart(context, payload) {
      await axios({
        method: 'GET',
        url: '/bulk/carts',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usr_tkn")}`,
        },
      })
      .then(({ data }) => {
        context.commit('FETCH_BULK_CARTS', data.data)
        context.commit('BADGE_BULK_LENGTH', data.data)
      })
      .catch((err) => {
        return err.response
      })
    },

    // UPDATE QTY
    updateQtyBulk(context, payload) {
      if(payload.newQty == 0) {
        this.dispatch('bulk_module/deleteCartBulk', { cart_id: payload.cart_id })
      } else {
        return axios({
          method: 'PATCH',
          url: `/carts/${payload.cart_id}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
            Accept: "application/json",
          },
          data: {
            qty: payload.newQty
          }
        })
        .then(({ res }) => {
          this.dispatch('bulk_module/fetchBulkCart')
        })
        .catch((err) => {
          return err.response
        })
      }
    },

    // DELETE MYCART
    deleteCartBulk(context, payload) {
      axios({
        method: 'GET',
        url: `/carts/delete/${payload.cart_id}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
          Accept: "application/json",
        },
      })
      .then(({ res }) => {
        this.dispatch('bulk_module/fetchBulkCart')
      })
      .catch((err) => {
        return err.response
      })
    },

    // CHECKOUT ORDER BULK
    checkoutOrderBulk(context, payload) {
      const parsedPayload = JSON.stringify(payload)
      localStorage.setItem('bulkCartItems', parsedPayload)
    },
    async confirmOrder(context, payload) {
      let bulkCheckout = await axios({
        url: '/bulk/orders/checkout',
        method: 'PUT',
        data: payload,
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("usr_tkn")}`,
        }
      })
      .then(({ data }) => {
        this.dispatch('bulk_module/fetchBulkCart')
        return data
      })
      .catch((err) => {
        Swal.fire({
          icon: 'warning',
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 3000
        })
        this.dispatch("carts_module/fetchCarts")
        return err.response
      })
      return bulkCheckout
    }

  },
  modules: {},
};

export default bulk_module;
