import axios from "@/API/axios";

const itemDetail_module = {
  namespaced: true,
  state: {
    itemDetailResult: {},
    itemImageResult: [],
    itemMerchantResult: "",
    itemStoreResult: "",
    similarProduct: [],
    shopInfo_link: {},
  },
  mutations: {
    fetchItemDetail(state, payload) {
      (state.shopInfo_link = payload),
        (state.similarProduct = payload.similarItem),
        (state.itemDetailResult = payload.item),
        (state.itemMerchantResult = payload.merchant),
        (state.itemStoreResult = payload.store);
      //mapping image array
      let image = state.itemDetailResult.images;
      let mapImage = [];
      for (let value of image) {
        mapImage.push(value.image_url);
      }
      state.itemImageResult = mapImage.slice(0, 10);
    },
    clearState(state, payload) {
      state.itemDetailResult = {};
    },
  },
  actions: {
    async itemDetail(context, payload) {
      return await axios({
        method: "GET",
        url: "/items/p/" + payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("usr_tkn"),
        },
      })
        .then(({ data }) => {
          context.commit("fetchItemDetail", data.data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    clearStateItemDetail(context, payload) {
      context.commit("clearState");
    },
  },
  modules: {},
};
export default itemDetail_module;
