export default [
  {
    path: '/bulk',
    name: 'BulkMain',
    meta: {'ja': "フードデリバリー"},
    component: () => import('../views/bulk/BulkMain.vue'),
    beforeEnter: (to, from, next) => {
      if(!localStorage.usr_tkn) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: '/bulk/search',
    name: 'BulkSearch',
    meta: {'ja': "検索結果"},
    component: () => import('../views/bulk/BulkSearch.vue')
  },
  {
    path: '/bulk/:slug_group/group-list',
    name: 'GroupList',
    meta: {'ja': "グループ一覧"},
    component: () => import('../views/bulk/GroupList'),
    beforeEnter: (to, from, next) => {
      if(!localStorage.usr_tkn) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: '/bulk/:slug_group/group-add',
    name: 'GroupAdd',
    meta: {'ja': "グループ追加"},
    component: () => import('../views/bulk/GroupAdd.vue'),
    beforeEnter: (to, from, next) => {
      if(!localStorage.usr_tkn) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: '/bulk/success',
    name: "SuccessPayment",
    meta: {'ja': "お支払い成功"},
    component: () => import('../views/bulk/SuccessPayment.vue'),
    beforeEnter: (to, from, next) => {
      if(!localStorage.usr_tkn) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: '/bulk/:slug',
    name: "BulkDetail",
    meta: {'ja': "グループ商品詳細"},
    component: () => import('../views/bulk/BulkDetail.vue'),
    beforeEnter: (to, from, next) => {
      if(!localStorage.usr_tkn) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  // ROUTE LAMA (UDAH GA KEPAKE ???) -----------
  //   path: '/bulk/group/:id',
  //   name: 'GroupJoin',
  //   meta: {'ja': "グループに入る"},
  //   component: () => import('../views/bulk/DetailGroup.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if(!localStorage.usr_tkn) {
  //       next({ name: 'Login' })
  //     } else {
  //       next()
  //     }
  //   }
  // },
  // {
  // ROUTE LAMA (UDAH GA KEPAKE ???) -----------
  //   path: '/bulk/failed',
  //   name: 'FailedPayment',
  //   meta: {'ja': "お支払い失敗"},
  //   component: () => import('../views/bulk/FailedPayment.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if(!localStorage.usr_tkn) {
  //       next({ name: 'Login' })
  //     } else {
  //       next()
  //     }
  //   }
  // },
]