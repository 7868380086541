import axios from '../../API/axios'

const allCategory_module = {
    namespaced: true,
    state: {
        allCategory: []
    },
    mutations: {
        fetchCategory (state, payload) {
            state.allCategory = payload
        }
    },
    actions: {
        async fetchAllCategory (context) {
            return await axios({
                method: 'GET',
                url: '/items/category',
            })
            .then(({ data }) => {
                context.commit('fetchCategory', data.data)
            })
            .catch((err) => {
              return err.response.data
            })
        }
    },
    modules: {

    }
}

export default allCategory_module